
import robonomics from "../robonomics";
import Quiz from "./Quiz";

export default {
  components: {
    Quiz
  },
  data() {
    return {
      result: null,
      error: null,
      unsubscribe: null,
      loader: false
    };
  },
  unmounted() {
    if (this.unsubscribe) {
      this.unsubscribe();
    }
  },
  methods: {
    async send(form) {
      this.error = "";
      this.result = "";
      this.loader = true;
      try {
        const tx = robonomics.datalog.write(
          JSON.stringify({ lesson: 4, data: form })
        );
        const resultTx = await robonomics.accountManager.signAndSend(tx);
        this.result = `${resultTx.blockNumber}-${resultTx.txIndex}`;
        localStorage.removeItem("lesson4quiz");
      } catch (error) {
        this.error = error.message;
      }
      this.loader = false;
    }
  }
};
